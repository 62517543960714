<template>
<div>
  <b-container fluid>
    <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
    <b-form v-if='statsAvailable' @submit="onSubmit">
      <b-row>
        <b-col cols='12' class='d-flex justify-content-center'>
          <b-card :title="team.name + ' v ' + game.opponentName"
                  :sub-title="game.location + ' - ' + formatDate(game.startDate.toDate())"
                  class="text-center">
            <table class="table b-table table-sm table-striped">
              <tr>
                <td colspan="4">{{ team.name }}</td>
                <td></td>
                <td colspan="4">{{ game.opponentName }}</td>
              </tr>
              <tr class="bold stats-input">
                <td>1P</td>
                <td>2P</td>
                <td>3P</td>
                <td>T</td>
                <td></td>
                <td>T</td>
                <td>1P</td>
                <td>2P</td>
                <td>3P</td>
              </tr>
              <tr v-for='stat in orderedStats' :key='stat.name' class="stats-input">
                <td><b-form-input v-model.number='stat.us.p1' type='number' ></b-form-input></td>
                <td><b-form-input v-model.number='stat.us.p2' type='number' ></b-form-input></td>
                <td><b-form-input v-model.number='stat.us.p3' type='number' ></b-form-input></td>
                <td><b-form-input :value='stat.us.p1 + stat.us.p2 + stat.us.p3' disabled ></b-form-input></td>
                <td class="stat-input-label" valign="middle">{{ stat.name | allcaps }}</td>
                <td><b-form-input :value='stat.them.p1 + stat.them.p2 + stat.them.p3' disabled></b-form-input></td>
                <td><b-form-input v-model.number='stat.them.p1' type='number' ></b-form-input></td>
                <td><b-form-input v-model.number='stat.them.p2' type='number' ></b-form-input></td>
                <td><b-form-input v-model.number='stat.them.p3' type='number' ></b-form-input></td>
              </tr>
            </table>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12' class='d-flex justify-content-center'>
          <b-card title='Scoring'>
            <table class="table scoring-input b-table table-sm table-striped">
              <tr>
                <td>{{ team.name }}</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <colgroup>
                      <col>
                      <col class="evenCol">
                      <col>
                      <col class="evenCol" span="2" >
                      <col span="5">
                      <col class="evenCol">
                    </colgroup>
                    <tr class="bold">
                      <td>Period</td>
                      <td>Time</td>
                      <td>Scored</td>
                      <td colspan="2">Assists</td>
                      <td colspan="5">On Ice</td>
                      <td>Type</td>
                    </tr>
                    <tr v-for='(goal, index) in game.scoring.us' :key='goal.index'>
                      <td><b-form-input v-model.number='goal.period' type='number' ></b-form-input></td>
                      <td><b-form-input v-model='goal.time' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.scored' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.assists[0]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.assists[1]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[0]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[1]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[2]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[3]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[4]' type='number' @blur.native='validatePlayerNumber' ></b-form-input></td>
                      <td><b-form-input v-model='goal.type'></b-form-input></td>
                      <td><span @click="removeGoal('us', index)"><font-awesome-icon icon="minus-circle"></font-awesome-icon></span></td>
                    </tr>
                    <tr>
                      <td colspan="11"><b-button size="sm" @click="addGoal('us')" variant="primary">+ Add Goal</b-button></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>{{ game.opponentName }}</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <colgroup>
                      <col>
                      <col class="evenCol">
                      <col>
                      <col class="evenCol" span="2" >
                      <col span="5">
                      <col class="evenCol">
                    </colgroup>
                    <tr class="bold scoring">
                      <td>Period</td>
                      <td>Time</td>
                      <td>Scored</td>
                      <td colspan="2">Assists</td>
                      <td colspan="5">On Ice ({{ team.name }})</td>
                      <td>Type</td>
                    </tr>
                    <tr v-for='(goal, index) in game.scoring.them' :key='goal.index'>
                      <td><b-form-input v-model.number='goal.period' type='number' ></b-form-input></td>
                      <td><b-form-input v-model='goal.time' ></b-form-input></td>
                      <td><b-form-input v-model.number='goal.scored' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.assists[0]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.assists[1]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[0]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[1]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[2]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[3]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='goal.onice[4]' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model='goal.type'></b-form-input></td>
                      <td><span @click="removeGoal('them', index)"><font-awesome-icon icon="minus-circle"></font-awesome-icon></span></td>
                    </tr>
                    <tr>
                      <td colspan="11"><b-button size="sm" @click="addGoal('them')" variant="primary">+ Add Goal</b-button></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12' class='d-flex justify-content-center'>
          <b-card title='Goalies'>
            <table class="table b-table table-sm table-striped">
              <tr>
                <td>{{ team.name }}</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <colgroup>
                      <col class="">
                      <col class="evenCol">
                      <col class="">
                      <col class="evenCol">
                    </colgroup>
                    <tr class="bold">
                      <td>Name</td>
                      <td>Jersey</td>
                      <td>Shots</td>
                      <td>GA</td>
                    </tr>
                    <tr v-for='goalie in game.goalieStats' :key='goalie.index'>
                      <td><b-form-input disabled v-model='goalie.name'></b-form-input></td>
                      <td><b-form-input disabled v-model='goalie.jerseyNumber' type='number'></b-form-input></td>
                      <td><b-form-input v-model.number='goalie.shots' type='number' ></b-form-input></td>
                      <td><b-form-input v-model.number='goalie.ga' type='number' ></b-form-input></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12' class='d-flex justify-content-center'>
          <b-card title='Penalties'>
            <table class="table scoring-input b-table table-sm table-striped">
              <tr>
                <td>{{ team.name }}</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <colgroup>
                      <col class="">
                      <col class="evenCol">
                      <col class="">
                      <col class="evenCol">
                    </colgroup>
                    <tr class="bold">
                      <td>Period</td>
                      <td>Player</td>
                      <td>Minutes</td>
                      <td>Time</td>
                    </tr>
                    <tr v-for='(penalty, index) in game.penalties.us' :key='penalty.index'>
                      <td><b-form-input v-model.number='penalty.period' type='number' ></b-form-input></td>
                      <td><b-form-input v-model.number='penalty.jerseyNumber' type='number' @blur.native='validatePlayerNumber'></b-form-input></td>
                      <td><b-form-input v-model.number='penalty.minutes' type='number' ></b-form-input></td>
                      <td><b-form-input v-model='penalty.time' ></b-form-input></td>
                      <td><span @click="removePenalty('us', index)"><font-awesome-icon icon="minus-circle"></font-awesome-icon></span></td>
                    </tr>
                    <tr>
                      <td colspan="10"><b-button size="sm" @click="addPenalty('us')" variant="primary">+ Add Penalty</b-button></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>{{ game.opponentName }}</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <colgroup>
                      <col class="">
                      <col class="evenCol">
                      <col class="">
                      <col class="evenCol">
                    </colgroup>
                    <tr class="bold scoring">
                      <td>Period</td>
                      <td>Player</td>
                      <td>Minutes</td>
                      <td>Time</td>
                    </tr>
                    <tr v-for='(penalty, index) in game.penalties.them' :key='penalty.index'>
                      <td><b-form-input v-model.number='penalty.period' type='number' ></b-form-input></td>
                      <td><b-form-input v-model.number='penalty.jerseyNumber' type='number' ></b-form-input></td>
                      <td><b-form-input v-model.number='penalty.minutes' type='number' ></b-form-input></td>
                      <td><b-form-input v-model='penalty.time' ></b-form-input></td>
                      <td><span @click="removePenalty('them', index)"><font-awesome-icon icon="minus-circle"></font-awesome-icon></span></td>
                    </tr>
                    <tr>
                      <td colspan="10"><b-button size="sm" @click="addPenalty('them')" variant="primary">+ Add Penalty</b-button></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class='justify-content-center'>
        <div class="submit" id='saveGameStats'>
          <b-button type="submit" variant="primary" v-if="invalidPlayerNumber === 0" >Save Game Stats!</b-button>
          <b-button type="submit" variant="warning" v-if="invalidPlayerNumber > 0" disabled >Save Game Stats!</b-button>
          <b-button variant="danger" @click="cancel()">Cancel</b-button>
          <b-btn v-if="isManager" class="reset" variant="link" @click.stop="reset()">Reset Game</b-btn>
        </div>
        <div style="position: absolute" v-if="invalidPlayerNumber > 0">Can't save stats because you have an invalid player number.</div>
      </b-row>
    </b-form>
  </b-container>
</div>
</template>

<script>

import moment from 'moment'
import { store } from '../store'
import { orderBy } from 'lodash-es'


export default {
  name: 'GameStatsInput',
  data () {
    return {
      loading: true,
      statsAvailable: false,
      availableStats: [],
      team: {},
      stats: [],
      goalieStats: [],
      scoring: [],
      penalties: [],
      invalidPlayerNumber: 0
    }
  },
  computed: {
    game () {
      return store.state.game
    },
    orderedStats () {
      return orderBy(this.game.stats, ['order'], ['asc'])
    }
  },
  async created () {
    this.invalidPlayerNumber = 0
    window.scrollTo(0,0)
    let gameId = this.$route.params.gameId
    let teamId = this.$route.params.teamId
    this.userProfile = store.state.userProfile
    if (this.userProfile.teams.includes(teamId)) {
      this.isMember = true
      if (this.userProfile.manages.includes(teamId)) {
        this.isManager = true
      }
      await store.commit('setCurrentTeamId', teamId)
      await store.dispatch('bindTeamData')
      this.team = store.state.team
      if (this.team.name) {
        this.setNav()
        await this.initGame(gameId)
        this.loading = false
        this.statsAvailable = true
        this.showStats = true
      }
    } else {
      this.isMember = false
      this.loading = false
      console.log('Not a member')
    }
  },
  methods: {
    // validate (next) {
    //   if (this.saveClicked) {
    //     next()
    //   } else {
    //     const answer = window.confirm('Are you sure you want to leave without saving?')
    //     if (answer) {
    //       next()
    //     } else {
    //       next(false)
    //     }
    //   }
    // },
    reset () {
      if(confirm('Whoa! Are you sure??')) {
        this.team.games.forEach((game, index) => {
          if (game.tsGameId === this.game.tsGameId) {
            this.team.games[index].gameScore = []
            this.team.games[index].playerStats = []
            this.team.games[index].statLines = []
            this.team.games[index].stats = []
            this.team.games[index].goalieStats = []
            delete this.team.games[index].scoring
            delete this.team.games[index].penalties
          }
        })
        localStorage.removeItem(this.game.tsGameId)
        store.dispatch('saveTeam', this.team).then(() => {
          this.$router.push({
            name: 'GameDetail',
            params: { gameId: this.game.tsGameId, teamId: this.team.tsId }
          })
        })
      }
    },
    setNav () {
      store.commit('updateNav', {
        backLink: '/team/' + this.$route.params.teamId + '/game/' + this.$route.params.gameId,
        teamLink: '/team/' + this.$route.params.teamId + '/game/' + this.$route.params.gameId,
        teamName: 'Back'
      })
    },
    formatDate (value) {
      if (!value) return ''
      return moment(value).format('MMMM D, YYYY')
    },
    async setGoalies () {
      let players = []
      this.team.players.forEach((player) => {
        if (player.position === 'Goalie') {
          let goalie = {
            name: player.firstName.substring(0,1) + `. ` + player.lastName,
            jerseyNumber: parseInt(player.jerseyNumber),
            shots: 0,
            ga: 0
          }
          this.game.goalieStats[goalie.jerseyNumber] = goalie
          // Remove goalies from player list because they never score, so what's the point?
        } else {
          players.push(player)
        }
      })
      this.game.activeGoalie = Object.keys(this.game.goalieStats)[0]
      this.game.players = players.sort(this.jerseySort)
    },
    async initGame (gameId) {
      await store.commit('setCurrentGameId', gameId)
      await store.dispatch('bindGameData')
      if (!('stats' in this.game)) {
        this.game.goalieStats = {}
        this.setGoalies()
        this.game.penalties = {
          them: [],
          us: []
        }
        this.game.scoring = {
          them: [],
          us: []
        }
        this.game.stats = {}
        this.team.statCategories.forEach((value, i) => {
          this.game.stats[value.name] = {
            name: value.name,
            order: i,
            us: {
              'p1': 0,
              'p2': 0,
              'p3': 0
            },
            them: {
              'p1': 0,
              'p2': 0,
              'p3': 0
            }
          }
        })
        // console.log(this.game)
        await store.dispatch('saveGame', this.game)
      }
      // console.log(this.game)
      return
    },
    cancel () {
      window.scrollTo(0,0)
      this.$router.push({
        name: 'GameDetail',
        params: { gameId: this.$route.params.gameId, teamId: this.$route.params.teamId }
      })
    },
    addGoal (team) {
      let goalObj = {
        period: '',
        scored: '',
        assists: [],
        onice: [],
        time: '',
        type: ''
      }
      this.scoring[team].push(goalObj)
    },
    removeGoal (team, index) {
      this.scoring[team].splice(index, 1)
    },
    addPenalty (team) {
      let penaltyObj = {
        period: '',
        playerNumber: '',
        minutes: '',
        time: ''
      }
      this.penalties[team].push(penaltyObj)
    },
    removePenalty (team, index) {
      this.penalties[team].splice(index, 1)
    },
    validatePlayerNumber (evt) {
      // console.log(evt)
      // console.log(evt.target.value)
      if (evt.target.value) {
        let player = this.team.players.find(function(p) {
          return p.jerseyNumber === parseInt(evt.target.value)
        })
        if (player === undefined ) {
          evt.target.classList.add('invalid')
          this.invalidPlayerNumber++
        } else if (evt.target.classList.contains('invalid')) {
          evt.target.classList.remove('invalid')
          this.invalidPlayerNumber--
        }
      } else {
        if (evt.target.classList.contains('invalid')) {
          evt.target.classList.remove('invalid')
          this.invalidPlayerNumber--
        }
      }
      // console.log('Invalid player nums: ', this.invalidPlayerNumber)
    },
    onSubmit (evt) {
      evt.preventDefault()
      this.saveClicked = 1
      this.loading = true
      this.showStats = false
      store.commit('setCurrentGameId', this.game.id)
      store.dispatch('saveGame', this.game).then(() => {
        this.$router.push({
          name: 'GameDetail',
          params: { gameId: this.game.id, teamId: this.team.id }
        })
      })
    }
  }
}
</script>
